const appConfig = {
    apiBasePrefix: `${process.env.REACT_APP_BASE_API_URL}`,
    apiPrefix: `${process.env.REACT_APP_API_URL}`,
    prodApiPrefix: `${process.env.REACT_APP_API_URL}`,
    authProdApiPrefix: `${process.env.REACT_APP_AUTH_API_URL}`,
    cdnPrefix: `${process.env.REACT_APP_CDN_URL}`,
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
