import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCompanyBrands } from 'services/CompanyBrandsService'

export const getCompanyBrands = createAsyncThunk(
    'shared/brands/getCompanyBrands',
    async (data) => {
        const response = await apiGetCompanyBrands(data)
        return response.data
    }
)

export const brandsSlice = createSlice({
    name: 'shared/brands',
    initialState: {
        data: [],
        selectedType: null,
        selectedGuid : null,
        loading: false,
    },
    reducers: {
        setSelectedType: (state, action) => {
            state.selectedType = action.payload
        },
        setSelectedGuid: (state, action) => {
            state.selectedGuid = action.payload
        },
    },
    extraReducers: {
        [getCompanyBrands.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        [getCompanyBrands.pending]: (state, action) => {
            state.loading = true
        },
    },
})

export const { setSelectedType, setSelectedGuid } = brandsSlice.actions

export default brandsSlice.reducer
