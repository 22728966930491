import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetOrdersQuery, apiGetStatsOrdersByWeek } from 'services/OrdersService'

export const getOrders = createAsyncThunk(
    'shared/orders/getOrders',
    async (data) => {
        const response = await apiGetOrdersQuery(data)
        return response.data
    }
)

export const getStatsOrdersByWeek = createAsyncThunk(
    'shared/orders/getStatsOrdersByWeek',
    async (data) => {
        const response = await apiGetStatsOrdersByWeek(data)
        return response.data
    }
)


const ordersSlice = createSlice({
    name: 'shared/orders',
    initialState: {
        loading: false,
        loadingStats: false,
        orderList: [],
        ordersStats: [],
    },
    reducers: {
        updateOrderList: (state, action) => {
            state.orderList = action.payload
        },
        
    },
    extraReducers: {
        [getOrders.fulfilled]: (state, action) => {
            state.orderList = action.payload.data
            state.loading = false
        },
        [getOrders.pending]: (state) => {
            state.loading = true
        },
        [getStatsOrdersByWeek.fulfilled]: (state, action) => {
            state.ordersStats = action.payload
            state.loadingStats = false
        },
        [getStatsOrdersByWeek.pending]: (state) => {
            state.loadingStats = true
        },
    },
})

export const {} = ordersSlice.actions

export default ordersSlice.reducer
