import { combineReducers } from '@reduxjs/toolkit'
import menu from './menusSlice'
import brands from './brandsSlice'
import brandMenu from './brandMenusSlice'
import metadatas from './metadatasSlice'
import orders from './ordersSlice'

const reducer = combineReducers({
    menu,
    brands,
    brandMenu,
    metadatas,
    orders,
})

export default reducer
